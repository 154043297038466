import React, { useEffect, useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import UserChat from '../userChat/selectUserChat';
import './input.css'; // Importar el archivo CSS

const InputComponent = ({ info, setInfo, handleInfoSubmit, showLoading }) => {
 const [description, setDescription] = useState("");
 const [sidebarOpen, setSidebarOpen] = useState(true);
  // Autoajustar altura del textarea
   const descriptionRef = useRef(null);

 useEffect(() => {
     if (descriptionRef.current) {
       descriptionRef.current.style.height = "auto";
       descriptionRef.current.style.height = 
         `${descriptionRef.current.scrollHeight}px`;
     }
   }, [info]);


  useEffect(() => {
    const handleFocus = (event) => {
      if (window.innerWidth <= 768) {
        // No hacemos nada, dejamos que el input se enfoque manualmente si el usuario lo desea
      }
    };

    const inputElement = document.querySelector('textarea');
    inputElement.addEventListener('focus', handleFocus);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <div >
    <textarea
      ref={descriptionRef}
      placeholder=" Ingresa cuerpo para generar contexto..."
      value={info}
      onChange={(e) => setInfo(e.target.value)}
      className="auto-resize-textarea-chat-trained"
      rows={1}
      disabled={showLoading}
    />
    <button 
      onClick={handleInfoSubmit} 
      disabled={showLoading}
      className="send-button-trained"
    >
      <FaArrowRight size={15} />
    </button>
   
  </div>
  );
};

export default InputComponent;

