// ButtonGroup.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Nuevo from './nuevo';
import Copy from './copy';
import Dash from './dashbaseBlack';
import { FaCheckCircle } from 'react-icons/fa';
import Tooltip from './Tooltip';

const ButtonGroup = ({ handleNewChat, handleCopy, showCopyIcon, username, idUserlink }) => {
  const navigate = useNavigate();
  const [visibleTooltip, setVisibleTooltip] = useState(null);

  // Refs para cada botón
  const nuevoRef = useRef(null);
  const copyRef = useRef(null);
  const dashRef = useRef(null);

  // Estilos del contenedor y del botón
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px', // espacio entre botones
    marginLeft: '30px' // separación del borde izquierdo del sidebar
  };

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer'
  };

  const iconStyle = {
    fontSize: '14px'
  };

  return (
    <div style={containerStyle}>
      <button
        style={buttonStyle}
        ref={nuevoRef}
        onClick={handleNewChat}
        onMouseEnter={() => setVisibleTooltip('nuevo')}
        onMouseLeave={() => setVisibleTooltip(null)}
      >
        <span style={iconStyle}><Nuevo size={12} /></span>
      </button>
    
      <button
        style={buttonStyle}
        ref={dashRef}
        onClick={() =>
          navigate('/dashboard', { state: { username: username, idUserlink: idUserlink } })
        }
        onMouseEnter={() => setVisibleTooltip('dash')}
        onMouseLeave={() => setVisibleTooltip(null)}
      >
        <span style={iconStyle}><Dash size={12} /></span>
      </button>

      <button
        style={buttonStyle}
        ref={copyRef}
        onClick={handleCopy}
        onMouseEnter={() => setVisibleTooltip('copy')}
        onMouseLeave={() => setVisibleTooltip(null)}
      >
        <span style={iconStyle}>
          {showCopyIcon ? <Copy size={12} /> : <FaCheckCircle style={{ color: 'green' }} />}
        </span>
      </button>

      {/* Renderiza los tooltips según el botón en hover */}
      {visibleTooltip === 'nuevo' && (
        <Tooltip targetRef={nuevoRef} visible={true}>
          Nuevo Chat
        </Tooltip>
      )}
     
      {visibleTooltip === 'dash' && (
        <Tooltip targetRef={dashRef} visible={true}>
          Mi Dash
        </Tooltip>
      )}

       {visibleTooltip === 'copy' && (
        <Tooltip targetRef={copyRef} visible={true}>
          {showCopyIcon ? 'Copiar' : 'Copiado'}
        </Tooltip>
      )}
    </div>
  );
};

export default ButtonGroup;

