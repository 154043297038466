import React, { useState, useRef, useEffect } from 'react';
import '../App.css'; 
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
} from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css'; 
import FilteredPosts from './historialTrained';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useScrollToBottom from './modulos-propios/useScrolledToBottom'; 
import useClickOutside from './modulos-propios/useClickOutside'; 
import ToolTipBarra from './icons/TooltipBarra';
import client from '../apolloClient';
import { useLocation  } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import InputComponent from './inputSinfoco/InputComponentTrained';

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true); 
   const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); 
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showLoading, setShowLoading] = useState(false);
   const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Entrenando el modelo...</span>);
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION);
  const [submitPostrain] = useMutation(SUBMIT_POSTRAIN_MUTATION);
 


  const useradmin = [
    process.env.REACT_APP_ADMIN,
    process.env.REACT_APP_ADMIN2,
    process.env.REACT_APP_ADMIN3,
    process.env.REACT_APP_ADMIN4,
    process.env.REACT_APP_ADMIN5,
    process.env.REACT_APP_ADMIN6
  ];

  const isAdmin = (currentUser) => {
    return useradmin.includes(currentUser);
  };

  const handleTitleSubmit = (event) => {
    event.preventDefault();
    if (title.trim() === '') {
      setMessage('Por favor ingresa un título.');
    } else {
      setMessage('');
      setStep(2);
    }
  };

  const handleInfoSubmit = (event) => {
    event.preventDefault();
    if (info.trim() === '') {
      setMessage('Por favor ingresa la información denominada cuerpo.');
    } else {
      setShowLoading(true);
      setMessage('');
      handleNewPostrain();
    }
  };
  
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');
  const name = user?.name?.replace(/[^a-zA-Z0-9]/g, '');
  const { loading, error, data, refetch } = useQuery(FETCH_POSTS_QUERY_USER_TAINED, {
    variables: { username }
  });
  const location = useLocation();

  const idUserlink = location.state?.selectedUser.id || '';
  const nombresUserlink = location.state?.selectedUser.nombres || '';

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRegenerate = () => {
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const [createdPost, setCreatedPost] = useState(null);

  const createPostMutation = () => {
    return client.mutate({
      mutation: SUBMIT_POSTRAIN_MUTATION,
      variables: { titulo: title , cuerpo: info, username: user.sub, idUserlink: idUserlink  },
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  };

  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
      console.log("Llamada a createPostMutation exitosa:", response.data.createPostTrain.id);
      const postId = response.data.createPostTrain.id;
      await refetch();
      navigate(`/singletrained/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  };

  const handleNewChat = () => {
    navigate(`/new/chat`);
    refetch();
  };

  const handleNewPostrain = () => {
    callCreatePost(refetch);
  };

  const sendMessage = () => {
    if (messageInput.trim() !== '') {
      const callCreatePost = async (refetch) => {
        try {
          const response = await createPostMutation();
          console.log("Llamada a createPostMutation exitosa:", response.data.createPost.id);
          const postId = response.data.createPost.id;

          submitComment({ 
            variables: { postId: postId, body: messageInput },
          });

          await refetch();
          navigate(`/chat/${postId}`);
        } catch (error) {
          console.error("Error al llamar a createPostMutation:", error);
        }
      };
      setShowLoading(true);
      callCreatePost(refetch);
      setMessageInput('');
    }
  };


  const handleSupervisar = () => {
    localStorage.clear()
    navigate(`/miequipo`)
  };
  

  return (
    <div className="chat-container">
      {isAuthenticated  && !createdPost && showLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
      ) : (
        isAuthenticated && (
          <div className={`spinner-container ${!sidebarOpen && 'expanded'}`}>  
          <div className="contexto-container" >
          <h3 style={{ fontWeight: 300 }}>
             ¡Hola <span style={{ color: "darkgray" }}>{name}</span>! Bienvenido al Entrenador de Conductual-GPT
          </h3>
          <p>
            Por favor, ingresa la información que deseas entrenar sobre{" "}
            <strong style={{ color: "black" }}>{nombresUserlink}</strong>.
           </p>
          </div>
        </div>
           
        )
      )}
      <div className="floating-menu">
      {isAuthenticated && ( <img src='/images/LOGO-conductualGPT-01.svg' className={`floating-menu-logo-2 ${!sidebarOpen && 'collapsed'}`} /> )}
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  {isAdmin(user.sub) && (
                       <>
                      
                       <button>
                         <a className='button-link' href="#" onClick={() => navigate(`/new/chat`)}>Ir al Chat</a>
                       </button>
                     
                       </>
                  )}
                  <button>
                    <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false)) }>Suscripcion</a>
                  </button>
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isAuthenticated && (
                 <div>
                 <ToolTipBarra sidebarOpen={sidebarOpen} content={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}>
                  <button 
                    className={`sidebar-toggle ${!sidebarOpen && 'collapsed'}`}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    style={{
                      position: 'fixed',
                      left: sidebarOpen ? '206px' : '20px',
                      top: '6px',
                      zIndex: 1000,
                      transition: 'all 0.3s ease',
                      background: '#fff',
                      border: '0px solid #ddd',
                      borderRadius: '50%',
                      width: '33px',
                      height: '33px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    {sidebarOpen ? '✕' : '☰'}
                  </button>
                </ToolTipBarra>
                       
                       <div className={`sidebar ${!sidebarOpen && 'closed'}`}>
                       
                       <div className="button-group">
                      
                        
                       </div>
                     
                       <div className="history-section">
                         <p>Historial</p>
                         <FilteredPosts refetch={refetch} user={user.sub} idUserlink={idUserlink}  />
                       </div>
                     </div>
                 
                  </div>
      )}
      <div className={`main ${!sidebarOpen && 'expanded'}`}>
        {isAuthenticated && (
          <div className="input-container">
            {step === 1 && (
              <form onSubmit={handleTitleSubmit}>
               
                  <input
                  className="input-titulo-trained"
                    type="text"
                    placeholder=" Ingresa título representativo del contexto..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                
               
                 <button type="submit" className="send-button-trained"><FaArrowRight size={15} /></button>
                {message && <p style={{ color: 'red' }}>{message}</p>}
              </form>
            )}
            {step === 2 && (
              
             <form onSubmit={handleInfoSubmit}>
               <InputComponent info={info} setInfo={setInfo} handleInfoSubmit={handleInfoSubmit} showLoading={showLoading}/>
             {message && <p style={{ color: 'red' }}>{message}</p>}
           </form>
           
            )}
          </div>
        )}
        <div className="disclaimer-base-container">
          <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
          <a className='button-link2' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        </div>
      </div>
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && isScrolledToBottom === false && (
            <div className="scroll-to-bottom" onClick={scrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
      
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};






const FETCH_POSTS_QUERY_USER_TAINED = gql`
  query getPostsTrainByUsername($username: String!) {
    getPostsTrainByUsername(username: $username) {
      id
      titulo
      createdAt
      username
    }
  }
`;

const FETCH_POST_QUERY = gql`
  query($postId: ID!) {
    getPost(postId: $postId) {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        username
        createdAt
        body
        respuesta
      }
    }
  }
`;

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($postId: String!, $body: String!) {
    createComment(postId: $postId, body: $body) {
      id
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
      commentCount
    }
  } 
`;


const SUBMIT_POSTRAIN_MUTATION = gql`
  mutation($titulo: String!, $cuerpo: String!, $username: String!, $idUserlink: String!) {
    createPostTrain(titulo: $titulo, cuerpo: $cuerpo, username: $username, idUserlink: $idUserlink) {
        id
        titulo
        resumen
        cuerpo
        tacs
        contexto{
          user
          assistant
        }
    }
  } 
`;


const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String!, $username: String!) {
    createPost(body: $body, username: $username) {
      id
      body
      estado
      createdAt
      username
      likes {
        id
        username
        createdAt
      }
      likeCount
      comments {
        id
        body
        respuesta
        username
        createdAt
      }
      commentCount
    }
  }
`;




export default Chat;
