// Tooltip.js
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Tooltip = ({ children, targetRef, visible }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (targetRef.current && visible) {
      const rect = targetRef.current.getBoundingClientRect();
      const tooltipHeight = 30; // altura aproximada del tooltip
      const gap = 5; // espacio entre el botón y el tooltip
      const top = rect.top - tooltipHeight - gap;
      const left = rect.left + rect.width / 2;
      setStyle({
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`,
        transform: 'translateX(-50%)',
        backgroundColor: 'black',
        color: '#fff',
        borderRadius: '4px',
        padding: '4px 8px',
        fontSize: '12px', // fuente de 10px
        zIndex: 9999, // para que siempre esté por encima
        whiteSpace: 'nowrap'
      });
    }
  }, [targetRef, visible]);

  if (!visible) return null;

  return createPortal(
    <div style={style}>
      {children}
    </div>,
    document.body
  );
};

export default Tooltip;
