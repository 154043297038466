import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import '../componentes/builderForm/styles.css';
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
    } from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTemporal';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useScrollToBottom from './modulos-propios/useScrolledToBottom';
import useClickOutside from './modulos-propios/useClickOutside';
import ButtonGroup from './icons/buttonGroup';
import ToolTipBarra from './icons/TooltipBarra';
import Nuevo from './icons/nuevo';
import Copy from './icons/copy';
import Dash from './icons/dashbaseBlack';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyComponent from './encuesta/SurveyComponent';
import HelpEncuesta from './helpEncuesta';
import UserChat from './userChat/selectUserChat';
import InputComponent from './inputSinfoco/InputComponent';

const useradmin = [
  process.env.REACT_APP_ADMIN,
  process.env.REACT_APP_ADMIN2,
  process.env.REACT_APP_ADMIN3,
  process.env.REACT_APP_ADMIN4,
  process.env.REACT_APP_ADMIN5,
  process.env.REACT_APP_ADMIN6
];

const isAdmin = (currentUser) => {
  return useradmin.includes(currentUser);
};

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [isModalHELP, setIsModalHELP] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [selectedUser, setSelectedUser] = useState('Selecionar usuario');
  const [idUserlink, setSidUserlink] = useState('');

  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Analizando patrón conductual...</span>);

  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION);
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');
  
  const location = useLocation();

  const { data: userConductualLinkData, loading: userConductualLinkLoading, error: userConductualLinkError, refetch: refetchUserConductualLink } = useQuery(FETCH_USERCONDUCTUAL_LINK_QUERY, {
    variables: { username },
    skip: !isAuthenticated
  });
  


  const { data: userConductualData, loading: loading, error: userConductualError, refetch: refetch } = useQuery(FETCH_USERCONDUCTUAL_QUERY, {
    variables: { username, idUserlink },
    skip: !isAuthenticated 

  });
  const userConductual = userConductualData?.getUserconductual;
  const destinatario = location.state?.destinatario || '';

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);


  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated, refetch]);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const [showInfo, setShowInfo] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(true);

  const handleCopy = () => {
    const chatText = currentChat.map(message => `${message.sender}: ${message.text}`).join('\n');
    navigator.clipboard.writeText(chatText);
    setShowInfo(true);
    setShowCopyIcon(false);
    setTimeout(() => {
      setShowInfo(false);
      setShowCopyIcon(true);
    }, 1500);
  };

  const handleRegenerate = () => {
    // Lógica para regenerar la respuesta del chatbot
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const [createdPost, setCreatedPost] = useState(null);

  const createPostMutation = () => {
    return client.mutate({
      mutation: CREATE_POST_MUTATION,
      variables: { body: 'Nuevo chat', username: username, idUserlink: idUserlink },
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  };

  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
     /// console.log("Llamada a createPostMutation exitosa:", response.data.createPost.id);
      const postId = response.data.createPost.id;
     /// await refetch();
      setShowLoading(false);
      navigate(`/chat/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  };

  const handleNewChat = () => {
    callCreatePost();
  };

  const sendMessage = () => {
    if (messageInput.trim() !== '') {
      const callCreatePost = async (refetch) => {
        try {
          const response = await createPostMutation();
          const postId = response.data.createPost.id;
  
          await submitComment({
            variables: { postId: postId, body: messageInput, username: username, idUserlink: idUserlink },
          });
  
          //await refetch();
          navigate(`/chat/${postId}`, { state: { selectedUser } });
        } catch (error) {
          console.error("Error al llamar a createPostMutation o submitComment:", error);
        }
      };
      setShowLoading(true);
      callCreatePost();
      setMessageInput('');
    }
  };
  
  


  const sampleDataOwner = {
    getUserconductual: userConductual,
  };

  const userConductualLINK = userConductualLinkData?.getUsersLinkByUsername || [];

const idUserlinkquery = String(userConductual?.id ?? '');


const michat = {
  __typename: 'getUsersLinkByUsername',
  id: idUserlinkquery,
  nombres: 'Yo mism@',
  createdAt: "2024-11-28T00:08:11.021Z",
};


const sampleData = {
  getUsersLinkByUsername: [...userConductualLINK, michat],
};


const handleNameSelect = (item) => {
  setSelectedUser(item);
  setSidUserlink(selectedUser.id);
  //refetch()
};


const handleSupervisar = () => {
  localStorage.clear()
  navigate(`/miequipo`)
};


  return (
    <div className="chat-container">
      {isAuthenticated && userConductual && !createdPost && showLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
  ) : (!createdPost && userConductual && userConductual !== null && (
    <div className={`spinner-container ${!sidebarOpen && 'expanded'}`}>  
      <div>
      {selectedUser === 'Selecionar usuario' && (
       <h2>
          Hola, <span style={{ color: '#808080', fontWeight: 300 }}>selecciona un usuario para comenzar a interactuar con el agente conductual...</span>
       </h2>
      )}

      {selectedUser.id === idUserlinkquery && userConductual !== null && (
        <h1>
          Hola, <span style={{ color: '#808080', fontWeight: 300 }}>¿en qué puedo ayudarte?</span>
        </h1>
       )}
        {selectedUser.id !== idUserlinkquery && userConductual !== null && selectedUser !== 'Selecionar usuario' && (
         <h3>
            Hola, <span style={{ color: '#808080', fontWeight: 300 }}>¿qué te gustaría saber sobre</span> {selectedUser.nombres}?
         </h3>
        )}

        
        {selectedUser.id === idUserlinkquery && userConductual !== null && (<h5>{`${user.name}`}</h5>)}
      </div>
    </div>
  ))}
  {userConductual === null && (
  <div style={{ width: '80%', height: '60vh', margin: '0 auto', position: 'relative' }}>
  <button
    className="question-button"
    onClick={() => setIsModalHELP(true)}
    style={{
      position: 'absolute',
      top: '150px',
      right: '180px', // Posiciona el botón 20px desde el borde derecho del contenedor
    }}
  >
    ?
  </button>
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      maxWidth: '500px', // Limita el ancho máximo del SurveyComponent
      margin: '0 auto', // Centra el SurveyComponent horizontalmente dentro de su contenedor
    }}
  >
    <SurveyComponent user={user.sub} email={user.email} refetch={refetch} />
  </div>
</div>
 
  )}
          <div className="floating-menu">
          {userConductual !== null &&( <UserChat data={sampleData} onNameSelect={handleNameSelect}  initialSearchTerm={destinatario} sidebarOpen={sidebarOpen} />)}
            {/* MENU DESPLEGABLE, Y LOGO QUE ESTA EN EL HEADER #PUBLICO# [Login]*/}
            {!isAuthenticated && ( <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo"/> )}

            {isAuthenticated && selectedUser !== 'Selecionar usuario' && ( <img src='/images/LOGO-conductualGPT-01.svg' className={`floating-menu-logo-2 ${!sidebarOpen && 'collapsed'}`} /> )}
            
            {selectedUser === 'Selecionar usuario' && ( <img src='/images/LOGO-conductualGPT-01.svg' className={`floating-menu-logo ${!sidebarOpen && 'collapsed'}`} /> )}
            
            <div className="sidebar-buttons">
              {!isAuthenticated && (
                <button onClick={() => loginWithRedirect()}>
                  <FaSignInAlt />
                  <span>Ingresar</span>
                </button>
              )}
            </div>
            {isAuthenticated && (
              <div className="profile-container">
                {/* MENU DESPLEGABLE, Y LOGO QUE ESTA EN EL HEADER #PUBLICO# [Salir, Entrenar, Suscripcion, Gestionar Links]*/}
                <img src={user.picture} alt={user.name} />
                <div id="dropdown" className="dropdown">
                  <button onClick={handleDropdownToggle}>
                    <FaCaretDown />
                  </button>
                  {showDropdown && (
                    <div className="dropdown-menu">

                          <button>
                            <a className='button-link' href="#" onClick={() => navigate(`/userlinkOwner`)}>Gestionar Links</a>
                          </button>

                          <button>
                            <a className='button-link' href="#" onClick={() => navigate(`/equipos`)}>Crear equipo</a>
                          </button>
  
                          <button>
                             <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false))}>Suscripción</a>
                           </button>

                           {isAdmin(user.sub) && selectedUser !== 'Selecionar usuario' && (<button className='button-link' onClick={() => navigate(`/trained`, { state: { selectedUser } })}>Entrenar</button>)}

                           {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={handleSupervisar}>Supervisar</a></button>)}
                     
                      <button onClick={() => {
                                   localStorage.clear(); // Borrar todo el localStorage
                                   logout({ logoutParams: { returnTo: window.location.origin } });
                            }}>
                         <span>Salir</span>
                        <FaSignOutAlt />
                      </button>

                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
  {/* {selectedUser !== 'Seleccionar usuario'&& (*/}
          {isAuthenticated && selectedUser !== 'Selecionar usuario' && (
           <div>
 <ToolTipBarra sidebarOpen={sidebarOpen} content={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}>
  <button 
    className={`sidebar-toggle ${!sidebarOpen && 'collapsed'}`}
    onClick={() => setSidebarOpen(!sidebarOpen)}
    style={{
      position: 'fixed',
      left: sidebarOpen ? '206px' : '20px',
      top: '6px',
      zIndex: 1000,
      transition: 'all 0.3s ease',
      background: '#fff',
      border: '0px solid #ddd',
      borderRadius: '50%',
      width: '33px',
      height: '33px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    }}
  >
    {sidebarOpen ? '✕' : '☰'}
  </button>
</ToolTipBarra>

       <div className={`sidebar ${!sidebarOpen && 'closed'}`}>
       
       <div className="button-group">
       <ButtonGroup
        handleNewChat={handleNewChat}
        handleCopy={handleCopy}
        showCopyIcon={showCopyIcon}
        username={username}
        idUserlink={idUserlink}
      />
        
       </div>
     
       <div className="history-section">
         <p>Historial</p>
         <FilteredPosts refetch={refetch} user={user.sub} idUserlink={idUserlink}  />
       </div>
     </div>         
  </div>
  )}
       {isAuthenticated && userConductual !== null && selectedUser !== 'Selecionar usuario' && (    
     <div className={`input-container-text ${!sidebarOpen && 'expanded'}`}>
         <InputComponent messageInput={messageInput} setMessageInput={setMessageInput} handleKeyDown={handleKeyDown} sendMessage={sendMessage} isStreaming={isStreaming} />
     </div>
    )}
    <div className={`main ${!sidebarOpen && 'expanded'}`}>
     
        <div className="disclaimer-base-container">
           <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
           <a className='button-link2' href="#" onClick={() => setIsModalOpen(true)}>Términos de uso.</a>
       </div>
     </div>

  
          {isAuthenticated && (
            <>
              {bodymessagesEndRef.current && !isScrolledToBottom && (
                <div className="scroll-to-bottom" onClick={scrollToBottom}>
                  <FaArrowDown />
                </div>
              )}
            </>
          )}
  
          <div className="disclaimer-base-container-terminos">
           
            <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
            <HelpEncuesta isOpen={isModalHELP} onClose={() => setIsModalHELP(false)} />
          </div>
        
      
    </div>
  );
  
};

const FETCH_POSTS_QUERY_USER = gql`
  query getPostsByUsername($username: String!, $idUserlink: String!) {
    getPostsByUsername(username: $username, idUserlink: $idUserlink) {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
    }
  }
`;

const FETCH_USERCONDUCTUAL_QUERY = gql`
  query($username: String!) {
    getUserconductual(username: $username) {
      id
      username
      disc
      eneagrama
      bigfive
    }
  }
`;


const FETCH_USERCONDUCTUAL_LINK_QUERY = gql`
  query($username: String!) {
    getUsersLinkByUsername(username: $username) {
      id
      nombres
      createdAt
    }
  }
`;

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($postId: String!, $body: String!, $username: String!, $idUserlink: String!) {
    createComment(postId: $postId, body: $body, username: $username, idUserlink: $idUserlink) {
      id
      comments {
        id
        idUserlink:
        body
        respuesta
        createdAt
        username
      }
      commentCount
    }
  } 
`;

const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String!, $username: String!, $idUserlink: String!) {
    createPost(body: $body, username: $username, idUserlink: $idUserlink) {
      id
      body
      estado
      createdAt
      username
      likes {
        id
        username
        createdAt
      }
      likeCount
      comments {
        id
        body
        respuesta
        username
        createdAt
      }
      commentCount
    }
  }
`;

export default Chat;
