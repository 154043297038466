import React, { useState, useRef, useEffect } from 'react';
import '../App.css'; 
import './encuesta/LoadingEffect.css';
import { 
    FaArrowDown, 
    FaDownload,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
} from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialEquipos';
import useScrollToBottom from './modulos-propios/useScrolledToBottom'; 
import useClickOutside from './modulos-propios/useClickOutside'; 
import Nuevo from './icons/nuevo';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
import { useNavigate, useLocation } from "react-router-dom";
import UserChat from './userChat/selectUserEquipo';
import ButtonGroup from './icons/buttonGroup';
import ToolTipBarra from './icons/TooltipBarra';

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true); 
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [message, setMessage] = useState('');
  const [inputView, setinputView] = useState(true);
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); 

  const [selectedUser, setSelectedUser] = useState('Selecionar usuario');
  const [idUserlink, setSidUserlink] = useState('');
  
  const [selectedIds, setSelectedIds] = useState([]);

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Procesando patrones conductuales. Creando el equipo...</span>);
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  
  const [submitPostrain] = useMutation(CREATE_LINK_MUTATION);

  const useradmin = [
    process.env.REACT_APP_ADMIN,
    process.env.REACT_APP_ADMIN2,
    process.env.REACT_APP_ADMIN3,
    process.env.REACT_APP_ADMIN4,
    process.env.REACT_APP_ADMIN5,
    process.env.REACT_APP_ADMIN6
  ];

  const isAdmin = (currentUser) => {
    return useradmin.includes(currentUser);
  };

  const handleTitleSubmit = (event) => {
    event.preventDefault();
    if (title.trim() === '') {
      setMessage('Por favor ingresa un título.');
    } else {
      setMessage('');
      setStep(2);
    }
  };

  const handleInfoSubmit = (event) => {
    event.preventDefault();
    if (info.trim() === '') {
      setMessage('Por favor ingresa la información denominada cuerpo.');
    } else {
      setMessage('');
      setInfo('');
      setinputView(false);
      handleNewPostrain();
      setShowLoading(true);
    }
  };
  
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');

  const { loading, error, data, refetchuserslink } = useQuery(FETCH_POSTS_QUERY_USER_LINK, {
    variables: { username }
  });

  const location = useLocation();


  const { data: userConductualLinkData2, loading: userConductualLinkLoading, error: userConductualLinkError, refetch: refetch } = useQuery(FETCH_USERCONDUCTUAL_LINK_QUERY, {
    variables: { username },
    skip: !isAuthenticated
  });
  


  const { data: userConductualData, loading: loadingUser, error: userConductualError, refetch: refetchUser } = useQuery(FETCH_USERCONDUCTUAL_QUERY, {
    variables: { username, idUserlink },
    skip: !isAuthenticated 

  });
  const userConductual = userConductualData?.getUserconductual;


  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRegenerate = () => {
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const [createdPost, setCreatedPost] = useState(null);

  const createPostMutation = () => {
    return client.mutate({
      mutation: CREATE_LINK_MUTATION,
      variables: { body: title , ids: selectedIds, username: username , emailOwner: user.email, informacion: info},
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  };

  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
     //// console.log("Llamada a createPostMutation exitosa:", response.data.createLinksTests.id);
      const postId = response.data.createPostEquipo.id;
      await refetch();
      setShowLoading(false);
      navigate(`/estadoEquipo/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  };

 

  const handleNewPostrain = () => {
    callCreatePost(refetch);
  };



  const sampleDataOwner = {
    getUserconductual: userConductual,
  };

  const userConductualLINK = userConductualLinkData2?.getUsersLinkByUsername2 || [];

const idUserlinkquery = String(userConductual?.id ?? '');


const michat = {
  __typename: 'UserConductualLink',
  id: idUserlinkquery,
  nombres: 'Yo mism@',
  createdAt: "2024-11-28T00:08:11.021Z",
};


const sampleData = {
  getUsersLinkByUsername2: [...userConductualLINK, michat],
};

const handleIdesChange = (ids) => {
  setSelectedIds(ids); // Aquí actualizas los IDs seleccionados
};

const handleChatNavigation = () => {
  localStorage.clear()
  navigate(`/new/chat`); // Navega a la página del chat
};

const handleSupervisar = () => {
  localStorage.clear()
  navigate(`/miequipo`)
};
 

  return (
    <div className="chat-container">
      {!createdPost && showLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
      ) : (
        <div className={`spinner-container ${!sidebarOpen && 'expanded'}`}> 
        <div className="contexto-container" >
          <h3>Crear equipo en Conductual-GPT</h3>
          <p>Por favor, seleccione al menos dos usuarios para formar un equipo. Si no tiene suficientes, <a href="#" onClick={() => navigate(`/userlinkOwner`)}>genere un Link</a>  e incorpore más usuarios.</p>


          <UserChat data={sampleData} onIdesChange={handleIdesChange} />
       
         
        </div>
      </div>
      )}
      <div className="floating-menu">
      {isAuthenticated && ( <img src='/images/LOGO-conductualGPT-01.svg' className={`floating-menu-logo-2 ${!sidebarOpen && 'collapsed'}`} /> )}
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  
                    <button>
                        <a className='button-link' href="#" onClick={handleChatNavigation}>Inicio</a>
                     </button>
                  
                     <button>
                            <a className='button-link' href="#" onClick={() => navigate(`/userlinkOwner`)}>Gestionar Links</a>
                     </button>
                 
                    <button>
                      <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false)) }>Suscripcion</a>
                    </button>
                   
                    {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={handleSupervisar}>Supervisar</a></button>)}
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isAuthenticated && (
      <div>
      <ToolTipBarra sidebarOpen={sidebarOpen} content={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}>
       <button 
         className={`sidebar-toggle ${!sidebarOpen && 'collapsed'}`}
         onClick={() => setSidebarOpen(!sidebarOpen)}
         style={{
           position: 'fixed',
           left: sidebarOpen ? '206px' : '20px',
           top: '6px',
           zIndex: 1000,
           transition: 'all 0.3s ease',
           background: '#fff',
           border: '0px solid #ddd',
           borderRadius: '50%',
           width: '33px',
           height: '33px',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           cursor: 'pointer'
         }}
       >
         {sidebarOpen ? '✕' : '☰'}
       </button>
     </ToolTipBarra>
            
            <div className={`sidebar ${!sidebarOpen && 'closed'}`}>
          
            <div className="history-section">
              <p>Historial</p>
              <FilteredPosts refetch={refetch} user={user.sub}/>
            </div>
          </div>
      
       </div>
      )}
      <div className={`main ${!sidebarOpen && 'expanded'}`}>
        {isAuthenticated && selectedIds.length >= 2 &&(
          <div className="input-container">
            {step === 1 && (
              <form onSubmit={handleTitleSubmit}>
                <label>
                  Ingresar nombre que le asignarás al equipo
                  <input
                    type="text"
                    placeholder="Ingresar nombre del equipo..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>
                <button type="submit" ><FaArrowRight size={15} /></button>
                {message && <p style={{ color: 'red' }}>{message}</p>}
              </form>
            )}
            {step === 2 && inputView === true &&(
              <form onSubmit={handleInfoSubmit}>
                <label>
                Ingrese la información o descripción de tareas que realizará el equipo.
                  <input
                    type="text"
                    placeholder="Ingresar información del equipo.."
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                  />
                </label>
                <button type="submit" ><FaArrowRight size={15} /></button>
                {message && <p style={{ color: 'red' }}>{message}</p>}
              </form>
            )}
          </div>
        )}
        <div className="disclaimer-base-container">
          <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
          <a className='button-link2' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        </div>
      </div>
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && isScrolledToBottom === false && (
            <div className="scroll-to-bottom" onClick={scrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
       
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};




const FETCH_POSTS_QUERY_USER_LINK = gql`
  query getPostsLinkByUsername($username: String!) {
    getPostsLinkByUsername(username: $username) {
      id
      linkOwner
      destinatario
      estado
      informacion
      createdAt
    }
  }
`;


const CREATE_LINK_MUTATION = gql`
mutation($body: String!, $ids: [String!], $username: String!, $emailOwner: String!, $informacion: String!){
  createPostEquipo(body: $body, username: $username, ids: $ids, emailOwner: $emailOwner, informacion: $informacion) {
    id
    idUserlink
    username
    createdAt
    ides
  }
  
}
`;

const FETCH_USERCONDUCTUAL_LINK_QUERY = gql`
  query($username: String!) {
    getUsersLinkByUsername2(username: $username) {
      id
      nombres
      createdAt
    }
  }
`;

const FETCH_USERCONDUCTUAL_QUERY = gql`
  query($username: String!) {
    getUserconductual(username: $username) {
      id
      username
      disc
      eneagrama
      bigfive
    }
  }
`;


export default Chat;
