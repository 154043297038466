import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';

function Tooltip({ children, content, sidebarOpen }) {
  const [show, setShow] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const ref = useRef();

  // Ref para almacenar el identificador del timer
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setCoords({
        top: rect.top - 8, 
        left: rect.left + rect.width / 2
      });
      // Inicia el timer con retardo de 2 segundos
      timerRef.current = setTimeout(() => {
        setShow(true);
      }, 700);
    }
  };

  const handleMouseLeave = () => {
    // Cancela el timer si aún está activo
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setShow(false);
  };

  const handleClick = () => {
    // Si se hace click antes de que termine el timer, se cancela
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setShow(false);
  };

  return (
    <>
      <span
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        style={{ display: 'inline-block' }}
      >
        {children}
      </span>
      {show &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: sidebarOpen ? '9px' : '50px', 
              left: sidebarOpen ? '55px' : '10px',
              background: 'rgba(0, 0, 0, 0.8)',
              color: '#fff',
              padding: '4px 8px',
              borderRadius: '4px',
              whiteSpace: 'nowrap',
              zIndex: 2000,
              pointerEvents: 'none'
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </>
  );
}

export default Tooltip;

