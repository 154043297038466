import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
    } from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTemporalSuper';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useScrollToBottom from './modulos-propios/useScrolledToBottom';
import useClickOutside from './modulos-propios/useClickOutside';
import Nuevo from './icons/nuevo';
import Copy from './icons/copy';
import Dash from './icons/dashbaseBlack';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyComponent from './encuesta/SurveyComponent';
import HelpEncuesta from './helpEncuesta';
import UserChat from './userChat/selectUserSuper';
import ToolTipBarra from './icons/TooltipBarra';

const useradmin = [
  process.env.REACT_APP_ADMIN,
  process.env.REACT_APP_ADMIN2,
  process.env.REACT_APP_ADMIN3,
  process.env.REACT_APP_ADMIN4,
  process.env.REACT_APP_ADMIN5,
  process.env.REACT_APP_ADMIN6
];

const isAdmin = (currentUser) => {
  return useradmin.includes(currentUser);
};

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [isModalHELP, setIsModalHELP] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [selectedUser, setSelectedUser] = useState('Selecionar usuario');
  const [idUserlink, setSidUserlink] = useState('');

  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Analizando patrón conductual...</span>);

  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');
  
  const location = useLocation();

  const { data: userConductualLinkData, loading: userConductualLinkLoading, error: userConductualLinkError, refetch: refetchUserConductualLink } = useQuery(FETCH_USERS_SUPER_QUERY);
  


  const { data: userConductualData, loading: loading, error: userConductualError, refetch: refetch } = useQuery(FETCH_USERCONDUCTUAL_QUERY, {
    variables: { username, idUserlink },
    skip: !isAuthenticated 

  });
  const userConductual = userConductualData?.getUserconductual;
  const destinatario = location.state?.destinatario || '';

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);


  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated, refetch]);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

 
  const sampleDataOwner = {
    getUserconductual: userConductual,
  };

  const userConductualLINK = userConductualLinkData?.getUsersConductualSuper || [];

const idUserlinkquery = String(userConductual?.id ?? '');





const sampleData = {
    getUsersConductualSuper: userConductualLINK,
};


const handleNameSelect = (item) => {
  setSelectedUser(item);
  setSidUserlink(selectedUser.id);
  console.log(selectedUser.name)
};

const handleChatNavigation = () => {
    localStorage.clear()
    navigate(`/new/chat`); // Navega a la página del chat
    //setSelectedUser('Selecionar usuario');
  };


  return (
    <div className="chat-container">
      {isAuthenticated && userConductual && showLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
  ) : (userConductual && userConductual !== null && (
    <div className={`spinner-container ${!sidebarOpen && 'expanded'}`}>  
      <div>
      {selectedUser === 'Selecionar usuario' && (
       <h2>
          Hola, <span style={{ color: '#808080', fontWeight: 300 }}>selecciona un usuario para supervisar sus chats unipersonales...</span>
       </h2>
      )}

      {selectedUser.id === idUserlinkquery && userConductual !== null && (
        <h1>
          Hola, <span style={{ color: '#808080', fontWeight: 300 }}>¿en qué puedo ayudarte?</span>
        </h1>
       )}
        {selectedUser.id !== idUserlinkquery && userConductual !== null && selectedUser !== 'Selecionar usuario' && (
         <h3>
            Hola, <span style={{ color: '#808080', fontWeight: 300 }}>Seleccioná un chat del historial de </span> {selectedUser.name} <span style={{ color: '#808080', fontWeight: 300 }}>para visualizarlo.</span>
         </h3>
        )}

        
        {selectedUser.id === idUserlinkquery && userConductual !== null && (<h5>{`${user.name}`}</h5>)}
      </div>
    </div>
  ))}

          <div className="floating-menu">
          {userConductual !== null &&( <UserChat data={sampleData} onNameSelect={handleNameSelect}  initialSearchTerm={destinatario} sidebarOpen={sidebarOpen}  />)}
            {/* MENU DESPLEGABLE, Y LOGO QUE ESTA EN EL HEADER #PUBLICO# [Login]*/}
            {isAuthenticated && ( <img src='/images/LOGO-conductualGPT-01.svg' className={`floating-menu-logo-2 ${!sidebarOpen && 'collapsed'}`} /> )}
            <div className="sidebar-buttons">
              {!isAuthenticated && (
                <button onClick={() => loginWithRedirect()}>
                  <FaSignInAlt />
                  <span>Ingresar</span>
                </button>
              )}
            </div>
            {isAuthenticated && (
              <div className="profile-container">
                {/* MENU DESPLEGABLE, Y LOGO QUE ESTA EN EL HEADER #PUBLICO# [Salir, Entrenar, Suscripcion, Gestionar Links]*/}
                <img src={user.picture} alt={user.name} />
                <div id="dropdown" className="dropdown">
                  <button onClick={handleDropdownToggle}>
                    <FaCaretDown />
                  </button>
                  {showDropdown && (
                    <div className="dropdown-menu">
                        <button>
                        <a className='button-link' href="#" onClick={handleChatNavigation}>Inicio</a>
                     </button>

                          <button>
                            <a className='button-link' href="#" onClick={() => navigate(`/userlinkOwner`)}>Gestionar Links</a>
                          </button>

                          <button>
                            <a className='button-link' href="#" onClick={() => navigate(`/equipos`)}>Crear equipo</a>
                          </button>
  
                          <button>
                             <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false))}>Suscripción</a>
                           </button>
                     
                      <button onClick={() => {
                                   localStorage.clear(); // Borrar todo el localStorage
                                   logout({ logoutParams: { returnTo: window.location.origin } });
                            }}>
                         <span>Salir</span>
                        <FaSignOutAlt />
                      </button>

                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
  {/* {selectedUser !== 'Seleccionar usuario'&& (*/}
          {isAuthenticated && selectedUser !== 'Selecionar usuario' && (
           
               <div>
               <ToolTipBarra sidebarOpen={sidebarOpen} content={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}>
                <button 
                  className={`sidebar-toggle ${!sidebarOpen && 'collapsed'}`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  style={{
                    position: 'fixed',
                    left: sidebarOpen ? '206px' : '20px',
                    top: '6px',
                    zIndex: 1000,
                    transition: 'all 0.3s ease',
                    background: '#fff',
                    border: '0px solid #ddd',
                    borderRadius: '50%',
                    width: '33px',
                    height: '33px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  {sidebarOpen ? '✕' : '☰'}
                </button>
              </ToolTipBarra>
             <div className={`sidebar ${!sidebarOpen && 'closed'}`}>
      
                     <div className="history-section">
                       <p>Historial</p>
                       <FilteredPosts refetch={refetch} user={selectedUser.username} idUserlink={idUserlink} select={useLocation} />
                     </div>
              </div>
     </div>
          )}
  
  <div className={`main ${!sidebarOpen && 'expanded'}`}>
     
        <div className="disclaimer-base-container">
           <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
           <a className='button-link2' href="#" onClick={() => setIsModalOpen(true)}>Términos de uso.</a>
       </div>
     </div>

  
  
          <div className="disclaimer-base-container-terminos">
          
            <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
            <HelpEncuesta isOpen={isModalHELP} onClose={() => setIsModalHELP(false)} />
          </div>
        
      
    </div>
  );
  
};


const FETCH_USERCONDUCTUAL_QUERY = gql`
  query($username: String!) {
    getUserconductual(username: $username) {
      id
      username
      disc
      eneagrama
      bigfive
    }
  }
`;

const FETCH_USERS_SUPER_QUERY = gql`
  query getUsersConductualSuper {
    getUsersConductualSuper {
        id
        username
        name
      }
  }
`;





export default Chat;
