import React, { useState, useRef, useEffect } from 'react';
//import { ApolloClient, InMemoryCache } from '@apollo/client';
import '../App.css'; // Estilos CSS para el chat
import {FaSignInAlt} from 'react-icons/fa'; // Importa los iconos de menú, cerrar, flecha hacia abajo, descarga, copiar y regenerar
import { useAuth0 } from "@auth0/auth0-react";
//import { useQuery } from '@apollo/react-hooks';
//import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Landing from './landing';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
//import FilteredPosts from './historial';
//import { Link } from 'react-router-dom';

///// Modulos propios
//import { generatePdf } from './modulos-propios/generatePDF';
//import { StreamingText } from './modulos-propios/streamingText';
//import useAutoScroll from './modulos-propios/useAutoScroll'; // Importa el módulo useAutoScroll
//import useScrollToBottom from './modulos-propios/useScrolledToBottom'; // Importa el módulo useScrollToBottom
//import useClickOutside from './modulos-propios/useClickOutside'; // Importa el módulo useClickOutside



const Chat_landiong = () => {
  //const [showSidebar, setShowSidebar] = useState(true); // Estado para controlar la visibilidad del sidebar
  const [showDropdown, setShowDropdown] = useState(false);
  //useClickOutside(showDropdown, setShowDropdown); // Utiliza el módulo useClickOutside
  //const [messages, setMessages] = useState([]);
  //const [currentChat, setCurrentChat] = useState([]);
 // const [isNew, setIsNew] = useState(false); // Variable de estado isNew agregada

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  //const [messageInput, setMessageInput] = useState('');
  //const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  //const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); // Utiliza el módulo useScrollToBottom
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  ///const { logout } = useAuth0();


  return (
    <div className="chat-container">
      {/* Landing */ isLoading}
      {isLoading ? (
         <div className="spinner-container">
            <div className="spinner">
               <img src='/images/logoFinalDark.png' alt="loading" />
            </div>
         </div>
            ) : (!isAuthenticated && (<Landing />))}
      
      {/* Mensajes chat */}
      {/* BARRA PARTE SUPERIOR */}
      <div className="floating-menu">
        {/* LOGO --- ISOLOGO */}
        <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo" />
        {/* CONTENIDO LOGIN--LOGOUT Y Menu */}
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
      </div>
      {/* Sidebar */}
      {/* Input chat */}
      {/* Scrolling Button */}
     
      <div className="disclaimer-base-container-terminos">
      <p >Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>

    </div>
  );
};


export default Chat_landiong;
