import React from 'react';

const TextoOrganizado = ({ texto }) => {
  // Contador para las líneas que el usuario numera manualmente
  let itemNumber = 1;

  // Función para quitar símbolos o números al inicio (por ejemplo, "1. ", "1). ", "1) ", "1- ", "-", "•", etc.)
  const removeLeadingSymbol = (str) => {
    return str.replace(/^(\d+\.\s|(\d+\)\.\s)|(\d+\)\s)|(\d+\-\s)|(\-|–|—|•)\s)/, '');
  };

  // Función que procesa todo el texto
  const parseText = (text) => {
    // Separamos el texto en líneas, ignorando las vacías
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const content = [];

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();

      // PRIMERA LÍNEA: incluye imagen + texto
      if (index === 0) {
        content.push(
          <p key={index}>
            <img
              src='/images/logoFinalDark.png'
              className='chat-img-bot'
              style={{ marginRight: '10px' }}
              alt="Logo"
            />
            {trimmedLine}
          </p>
        );
        return;
      }

      // Nueva condición para líneas numeradas del tipo "1. ", "1). ", "1) ", "1-"
      const numberedPattern = /^(\d+(?:\.|\.\)|\)|\-))\s*(.*)$/;
      const numberedMatch = trimmedLine.match(numberedPattern);
      if (numberedMatch) {
        // Extraemos la parte de puntuación removiendo los dígitos originales
        const punctuationPart = numberedMatch[1].replace(/^\d+/, '');
        // Usamos nuestro contador "itemNumber" junto con la parte de puntuación
        const newPrefix = `${itemNumber}${punctuationPart} `;
        const restText = numberedMatch[2];

        // Verificamos si en el texto restante se encuentra ":"
        const colonIndex = restText.indexOf(':');
        if (colonIndex !== -1) {
          // Se muestra en negrita desde el nuevo prefijo hasta (e incluyendo) el primer ":"
          const boldSegment = newPrefix + restText.substring(0, colonIndex + 1);
          const normalSegment = restText.substring(colonIndex + 1);
          content.push(
            <p key={index}>
              <strong style={{ fontSize: '1.1rem' }}>
                {boldSegment}
              </strong>
              {normalSegment}
            </p>
          );
        } else {
          // Si no hay ":", sólo se muestra en negrita el prefijo y el resto en normal
          content.push(
            <p key={index}>
              <strong style={{ fontSize: '1.1rem' }}>
                {newPrefix}
              </strong>
              {restText}
            </p>
          );
        }
        itemNumber++;
        return;
      }

      // 1) Si la línea TERMINA con ":", la mostramos en negrita sin ningún símbolo delante
      if (trimmedLine.endsWith(':')) {
        // Eliminamos símbolos si hay
        const textWithoutSymbol = removeLeadingSymbol(trimmedLine).trim();
        content.push(
          <p key={index}>
            <strong>{textWithoutSymbol}</strong>
          </p>
        );
        return;
      }

      // 3) Si la línea empieza con un símbolo de viñeta, la mostramos como párrafo normal
      //    (Si prefieres una lista real, puedes adaptarlo con <ul> o <li>)
      if (/^(\-|–|—|•)\s/.test(trimmedLine)) {
        const textWithoutSymbol = removeLeadingSymbol(trimmedLine);
        content.push(
          <p key={index} dangerouslySetInnerHTML={{ __html: formatText(textWithoutSymbol) }}></p>
        );
        return;
      }

      // 4) Cualquier otra línea => párrafo normal
      content.push(
        <p
          key={index}
          dangerouslySetInnerHTML={{ __html: formatText(trimmedLine) }}
        ></p>
      );
    });

    return content;
  };

  // Convierte **texto** en <strong>texto</strong>
  const formatText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return <>{parseText(texto)}</>;
};

export default TextoOrganizado;